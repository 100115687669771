import {Report, ReportItem} from '../../fondue-api/generated-src';

const ReportItemTypes = {
    'header': { "value": "header", "label": "header" },
    'query_single_result':  { "value": "query_single_result", "label": "query_single_result"},
    'query_multiple_result':  { "value": "query_multiple_result", "label": "query_multiple_result"}
};

const DerReportItemTypes = {
    "der_policy_scan": {"value": "der_policy_scan", "label": "der_policy_scan" }
}

export const PolicyDER = "PolicyDER"

/**
* Return true if value is DER report item type
*/
export function IsDerReportItemType(value: string | ReportItem |null): boolean {
    if (typeof value == "string") {
        return value in DerReportItemTypes;
    } else if (typeof value == "object" && value) {
        return "report_item_config" in value && "der_config" in value.report_item_config!;
    }
    
    return false;
}

/**
 * Return report item types for @param report
 * @param report Report object
 * @returns 
 */
export function GetReportItemTypes(report?: Report): Object {
    let itemTypes = ReportItemTypes;

    if (report?.category == PolicyDER) {
        itemTypes = {...itemTypes, ...DerReportItemTypes};
    }

    return itemTypes;
}