import { useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import Form from "@amzn/awsui-components-react/polaris/form";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Flashbar, {FlashbarProps} from "@amzn/awsui-components-react/polaris/flashbar";
import Input from '@amzn/awsui-components-react/polaris/input';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Header from '@amzn/awsui-components-react/polaris/header';
import Button from '@amzn/awsui-components-react/polaris/button';
import Container from '@amzn/awsui-components-react/polaris/container';
import Link from '@amzn/awsui-components-react/polaris/link';
import Checkbox from "@amzn/awsui-components-react/polaris/checkbox";


import FondueApiFactory from '../../fondue-api/FondueApiFactory';
import { Report, FondueApi } from '../../fondue-api/generated-src';
import {getMidwayJwtToken} from "../../auth/MidwayJwtToken";
import axios from "axios";
import {getAppSetting, initializeAppSettings} from "../../config/AppSettings";

const defaultBindle = "amzn1.bindle.resource.iptmumwaqe4boezjseca"
const derReportCategory = "PolicyDER"

const flashbarInvalidInput: FlashbarProps.MessageDefinition[] = [
    { type: 'error', content: 'Invalid Input: Name and Owner are required fields', dismissible: true }
];

const flashbarSubmittingInput: FlashbarProps.MessageDefinition[] = [
    { type: 'info', content: 'Creating Report', dismissible: true }
];

export default function ReportsFormPage({setFlashbar}) {
    Object.freeze(Object.prototype);
    async function createReport() {
        await getMidwayJwtToken();
        // If name or owner field not present, set InvalidInput Flashbar
        if(!reportName || !reportOwner || !reportBindle) {
            setFlashbar(flashbarInvalidInput)
            return;
        }

        const FondueApi = FondueApiFactory();

        const report: Report = {
            id: '',
            name: reportName,
            owner: reportOwner,
            state: 'DISABLED',
            category: reportCategory,
            project: reportProject,
            report_item_order: [],
            bindle: reportBindle
        };
        setFlashbar(flashbarSubmittingInput)
        setCreateButtonDisabled(true);
        await FondueApi.createReport(report);

        // Redirect to Reports Page after submitting
        history.push('/reports')

    }


    const [reportName, setReportName] = useState("")
    const [reportOwner, setReportOwner] = useState("")
    const [reportCategory, setReportCategory] = useState("")
    const [reportProject, setReportProject] = useState("")
    const [reportBindle, setReportBindle] = useState(defaultBindle)
    const [createButtonDisabled, setCreateButtonDisabled] = useState(false);
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);

    const [useDefaultBindle, setUseDefaultBindle] = useState(true);
    const [isDerReportType, setIsDerReportType] = useState(false);
    const [isUserDerAuthorized, setisUserDerAuthorized] = useState(false);
 
    const history = useHistory();

    function updateCheckboxFunction(detail){
        setUseDefaultBindle(detail.checked);
        setReportBindle(detail.checked?defaultBindle:"");
    }

    function updateDerReportCheckboxFunction(detail){
        setIsDerReportType(detail.checked);
        setReportCategory(detail.checked?derReportCategory:"");
    }

    async function validateUserDerAuthorization(FondueApi: FondueApi) {
        await getMidwayJwtToken();
        await FondueApi.validateDerAuthorization()
            .then((response) => {
                console.log(response);
                if (response.data.authorized){
                    setReportCategory(derReportCategory);
                    setIsDerReportType(true);
                    setisUserDerAuthorized(true);
                }
            });
        setSubmitButtonDisabled(false); //enable submit button once authz for DER is complete
    }

    useEffect(() => {
        (async () => {
            const FondueApi = FondueApiFactory();
            await validateUserDerAuthorization(FondueApi)
        })();
    }, [FondueApiFactory]);

    return (
        <Container
            header={
                <Header variant="h2" description="Create Fondue Report">
                    Create Report
                </Header>
            }
        >
            <Form
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button id="cancel" variant="link" href={`#/reports`}>
                            Cancel
                        </Button>
                        <Button id="submit" variant="primary" onClick={createReport} disabled={createButtonDisabled || submitButtonDisabled}>
                            Submit
                        </Button>
                    </SpaceBetween>
                }
            >
                <FormField id="name" description="Name of Report." label="Report Name">
                    <Input
                        value = {reportName}
                        onChange={event =>
                            setReportName(event.detail.value)}
                    />
                </FormField>
                <FormField id="owner" description="Business Owner of Report." label="Report Owner">
                    <Input
                        value={reportOwner}
                        onChange={event =>
                            setReportOwner(event.detail.value)
                        }
                    />
                </FormField>
                <FormField
                    id="category"
                    description="Category of Report."
                    label={
                        <span>
                          Report Category <i> - optional</i>{" "}
                        </span>
                    }
                >
                    <Input
                        disabled={isDerReportType}
                        value={reportCategory}
                        onChange={event =>
                            setReportCategory(event.detail.value)
                        }
                    />
                </FormField>
                <FormField
                    id="project"
                    description="Project type of Report."
                    label={
                        <span>
                          Report Project <i> - optional</i>{" "}
                        </span>
                    }
                >
                    <Input
                        value={reportProject}
                        onChange={event =>
                            setReportProject(event.detail.value)
                        }
                    />
                </FormField>
                <FormField
                    id="bindle"
                    description={
                        <div>
                            Parent bindle of Report.
                            <Link
                              href="https://w.amazon.com/bin/view/BusinessMetrics/Fondue/Bindles"
                              target="_blank"
                            >
                              Bindle setup details
                            </Link>
                        </div>
                    }
                    label="Parent Bindle">
                    <Input
                        disabled={useDefaultBindle}
                        value = {reportBindle}
                        onChange={event =>

                            setReportBindle(event.detail.value)}
                    />
                </FormField>
                <Checkbox
                    id="checkbox"
                    onChange={({ detail }) =>
                        updateCheckboxFunction(detail)
                    }
                    checked={useDefaultBindle}
                >
                  <div>
                      {"Use default "}
                      <Link
                        href="https://bindles.amazon.com/software_app/BizmetFondueReports"
                        target="_blank"
                      >
                        Business Metrics owned Bindle
                      </Link>
                  </div>
                </Checkbox>
                { isUserDerAuthorized ?  
                    <Checkbox
                        onChange={({ detail }) => updateDerReportCheckboxFunction(detail)}
                        checked={isDerReportType}
                    >
                        <div>
                            {"Report is for Data Extraction Requests (DER)"}
                        </div>
                    </Checkbox> : ''
                }
            </Form>
        </Container>
    );

}
