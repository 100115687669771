import React, { Component, useEffect, useState } from 'react';
import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import Flashbar, { FlashbarProps } from '@amzn/awsui-components-react/polaris/flashbar';
import { Breadcrumbs, ServiceNavigation } from '../navigation';
import { appLayoutLabels } from '../../common/labels';
import {Box, Grid, ColumnLayout, Header, Table, SpaceBetween, Container,} from '@amzn/awsui-components-react/polaris';
import Button from "@amzn/awsui-components-react/polaris/button";
import { useHistory } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { States } from "../../common/States";
import { Report, ReportItem, ReportSchedule, FondueApi } from '../../fondue-api/generated-src';
import ReportResultDateSelection from './ReportResultDateSelection';
import FondueApiFactory from "../../fondue-api/FondueApiFactory";
import { getMidwayJwtToken } from "../../auth/MidwayJwtToken";

export default function ReportResultView() {
    Object.freeze(Object.prototype);
    const { report_id } = useParams<{report_id: string}>();
    const [report, setReport] = useState<Report>();
    const [reportName, setReportName] = useState("");
    const [state, setState] = useState<States>(States.loading)
    const [flashbar, setFlashbar] = useState<FlashbarProps.MessageDefinition[]>([])

    const flashbarReportLoading: FlashbarProps.MessageDefinition[] = [
        {
            type: 'info', content: 'Loading report result selection', dismissible: true,
            onDismiss: () => setFlashbar([])
        }
    ];
    const flashbarReportLoadingError: FlashbarProps.MessageDefinition[] = [
        {
            type: 'error', content: 'User does not have permission to view this Report Result', dismissible: true,
            onDismiss: () => setFlashbar([])
        }
    ];


    async function getReport(FondueApi: FondueApi, counter: number) {
        await getMidwayJwtToken();
        if(counter < 3){
            await FondueApi.getReport(report_id)
                .then((response) =>{
                    setReport(response.data)
                    setReportName(response.data['name']);
                    setFlashbar([]);
                })
                .catch((e) => {
                    getReport(FondueApi, counter + 1)
                    setFlashbar(flashbarReportLoadingError);
                })
        }
    }

    useEffect(() => {
        (async () => {
            setFlashbar(flashbarReportLoading)
            const FondueApi = FondueApiFactory();

            await getReport(FondueApi, 0)

        })();
    }, []);



    return (
        <AppLayout
            navigation={<ServiceNavigation />}
            notifications={<Flashbar items={flashbar} />}
            breadcrumbs={<Breadcrumbs items={[
                { text: 'Reports Table', href: '#/reports' },
                { text: reportName, href: '#/reports/'+ report_id },
                { text: 'Report Result', href: '' }]} />}
            content={
                <Box variant="h1">
                    {!!report && reportName + " Result" || "Report"}
                    {<ReportResultDateSelection reportName={reportName} reportCategory={report?.category} setState={setState} setFlashbar={setFlashbar}/>}
                </Box>
            }
            contentType="table"
            ariaLabels={appLayoutLabels}
        />
    );
}