export enum States {
    loading,
    success,
    error,
    submitting,
    invalid,
    forbidden,
    failedCreate,
    deletingReport,
    failedDeleteReport,
    deleteReportSuccess,
    savingReport,
    saveReportSuccess,
    updatingReportItem,
    updateReportItemSuccess,
    updateReportItemFailed,
    getReportForbidden,
    deleteReportForbidden,
    updateReportForbidden,
    getReportItemForbidden,
    updateReportItemForbidden
}
