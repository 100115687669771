import { Component, useEffect, useState } from 'react';
import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import Flashbar, { FlashbarProps } from '@amzn/awsui-components-react/polaris/flashbar';
import { Breadcrumbs, ServiceNavigation } from '../navigation';
import { appLayoutLabels } from '../../common/labels';
import './styles.scss';
import { Box, Grid, ColumnLayout, Header, Table, SpaceBetween, Alert, Container } from '@amzn/awsui-components-react/polaris';
import Button from "@amzn/awsui-components-react/polaris/button";
import { useHistory } from 'react-router-dom';
import { useParams } from "react-router-dom";
import ReportContainer from "./ReportContainer";
import ReportItemsTable from './ReportItemsTable';
import ReportTableProps from './ReportItemsTable'
import ReportItemForm from './ReportItemForm';
import { Report, ReportItem, FondueApi } from "../../fondue-api/generated-src";
import { States } from "../../common/States";
import FondueApiFactory from '../../fondue-api/FondueApiFactory';
import EditMetricForm from './EditMetricForm';
import ExpandableSection from "@amzn/awsui-components-react/polaris/expandable-section";
import { getMidwayJwtToken } from "../../auth/MidwayJwtToken";


const flashbarItemsLoading: FlashbarProps.MessageDefinition[] = [
    { type: 'info', content: 'Loading report', dismissible: true }
];
const flashbarItemsComplete: FlashbarProps.MessageDefinition[] = [
    { type: 'success', content: 'Report loaded successfully', dismissible: true }
];
const flashbarItemsFailed: FlashbarProps.MessageDefinition[] = [
    { type: 'error', content: 'Report failed to load', dismissible: true }
];
const flashbarCreatingReportItem: FlashbarProps.MessageDefinition[] = [
    { type: 'info', content: 'Creating new report item', dismissible: true }
];
const flashbarDeletingReport: FlashbarProps.MessageDefinition[] = [
    { type: 'info', content: 'Deleting report', dismissible: true }
];
const flashbarDeleteReportSuccess: FlashbarProps.MessageDefinition[] = [
    { type: 'success', content: 'Successfully deleted report. Redirecting to reports page', dismissible: true }
];
const flashbarFailedDeleteReport: FlashbarProps.MessageDefinition[] = [
    { type: 'error', content: 'Failed to delete report', dismissible: true }
];
const flashbarInvalidReportItem: FlashbarProps.MessageDefinition[] = [
    { type: 'error', content: 'Invalid input for report item', dismissible: true }
];
const flashbarFailedCreateItem: FlashbarProps.MessageDefinition[] = [
    { type: 'error', content: 'Failed to create new report item', dismissible: true }
];
const flashbarUpdatingReportItem: FlashbarProps.MessageDefinition[] = [
    { type: 'info', content: 'Updating report item', dismissible: true }
];
const flashbarUpdateReportItemSuccess: FlashbarProps.MessageDefinition[] = [
    { type: 'success', content: 'Successfully updated report item', dismissible: true }
];
const flashbarUpdateReportItemFailed: FlashbarProps.MessageDefinition[] = [
    { type: 'error', content: 'Failed to update report item', dismissible: true }
];
const flashbarGetReportForbidden: FlashbarProps.MessageDefinition[] = [
    { type: 'error', content: 'User does not have authorization to view this report', dismissible: true }
];
const flashbarDeleteReportForbidden: FlashbarProps.MessageDefinition[] = [
    { type: 'error', content: 'User does not have authorization to delete this report', dismissible: true }
];

const flashbarSavingReport: FlashbarProps.MessageDefinition[] = [
    { type: 'info', content: 'Saving report order', dismissible: true }
];

const flashbarSaveReportSuccess: FlashbarProps.MessageDefinition[] = [
    { type: 'success', content: 'Successfully updated report order', dismissible: true }
];


export default function ReportItemsTableView() {
    Object.freeze(Object.prototype);
    const { report_id } = useParams<{report_id: string}>();
    const [state, setState] = useState<States>(States.loading)
    const [reportName, setReportName] = useState("");
    const [flashbar, setFlashbar] = useState<FlashbarProps.MessageDefinition[]>(flashbarItemsLoading)
    const [refreshTable, setRefreshTable] = useState<boolean>(false);
    const [report, setReport] = useState<Report>();
    const [reportItem, setReportItem] = useState<ReportItem>();

    async function getReport(FondueApi: FondueApi, counter: number) {
        await getMidwayJwtToken();
        if(counter < 3){
            await FondueApi.getReport(report_id)
                .then((response) =>{
                    setReport(response.data)
                })
                .catch((e) => {
                    getReport(FondueApi, counter + 1)
                    if(e.response.status=403){
                        console.log('User is not authorized to view this report.')
                        setState(States.getReportForbidden)
                    }
                })
        }
    }
    const [addEditInfoDisabled, setAddEditInfoDisabled] = useState(false);
    const [addDisabled, setAddDisabled] = useState(true);
    const [editDisabled, setEditDisabled] = useState(true);

    useEffect(() => {
        if(state === States.loading){
            setFlashbar(flashbarItemsLoading)
        }
        else if(state === States.success){
            setFlashbar(flashbarItemsComplete)
        }
        else if(state === States.submitting){
            setFlashbar(flashbarCreatingReportItem)
        }
        else if(state === States.invalid){
            setFlashbar(flashbarInvalidReportItem)
        }
        else if(state === States.deletingReport){
            setFlashbar(flashbarDeletingReport)
        }
        else if(state === States.failedDeleteReport){
            setFlashbar(flashbarFailedDeleteReport)
        }
        else if(state === States.deleteReportSuccess){
            setFlashbar(flashbarDeleteReportSuccess)
        }
        else if(state === States.failedCreate){
            setFlashbar(flashbarFailedCreateItem)
        }
        else if(state === States.savingReport){
            setFlashbar(flashbarSavingReport)
        }
        else if(state === States.saveReportSuccess) {
            setFlashbar(flashbarSaveReportSuccess)
        }
        else if(state === States.updatingReportItem){
            setFlashbar(flashbarUpdatingReportItem)
        }
        else if(state === States.updateReportItemSuccess){
            setFlashbar(flashbarUpdateReportItemSuccess)
        }
        else if(state === States.updateReportItemFailed){
            setFlashbar(flashbarUpdateReportItemFailed)
        }
        else if(state === States.getReportForbidden){
            setFlashbar(flashbarGetReportForbidden)
        }
        else if(state === States.deleteReportForbidden){
            setFlashbar(flashbarDeleteReportForbidden)
        }
        else{
            setFlashbar(flashbarItemsFailed)
        }


    }, [state, flashbar, refreshTable, setRefreshTable]);

    return (
        <AppLayout
            navigation={<ServiceNavigation />}
            notifications={<Flashbar items={flashbar} />}
            breadcrumbs={<Breadcrumbs items={[
                { text: 'Reports Table', href: '#/reports' },
                { text: reportName, href: '' }]} />}
            content={
                <Box >
                    <Header
                        variant="h2"
                    >
                    </Header>
                    <Grid
                        gridDefinition={[
                            { colspan: { default: 3, xxs: 6} },
                            { colspan: { default: 12, xxs: 6 } }
                        ]}
                    >
                        <div>
                            <div>
                                <Box padding={{bottom: "s"}}>
                                    <ReportContainer report={report as Report} setReport={setReport} setReportName={setReportName} setState={setState} setAddDisabled={setAddDisabled} setEditDisabled={setEditDisabled} setAddEditInfoDisabled={setAddEditInfoDisabled}/>
                                </Box>
                            </div>
                            <div>
                                <Box padding={{bottom: "s"}}>
                                    <ReportItemsTable setState={setState} state={state} refreshTable={refreshTable} setReportItem={setReportItem} setRefreshTable={setRefreshTable} report={report as Report} setAddDisabled={setAddDisabled} setEditDisabled={setEditDisabled} setAddEditInfoDisabled={setAddEditInfoDisabled}/>
                                </Box>
                            </div>
                        </div>
                        <div>
                            {
                                !addEditInfoDisabled ? (
                                    <Alert
                                        onDismiss={() => setAddEditInfoDisabled(true)}
                                        visible={!addEditInfoDisabled}
                                        dismissAriaLabel="Close alert"
                                        header="Add/Edit Item">
                                        <ul>
                                            <li>Click <b>[Add Item]</b> to add new item in report</li>
                                            <li>Select <b>single</b> item to edit</li>
                                            <li>Select <b>single/multiple</b> items to move</li>
                                        </ul>
                                    </Alert>
                                ) : (!addDisabled) ? (
                                    <ReportItemForm setState={setState} report={report as Report} setRefreshTable={setRefreshTable} setAddDisabled={setAddDisabled} setEditDisabled={setEditDisabled} setAddEditInfoDisabled={setAddEditInfoDisabled}/>
                                ) : (!editDisabled) ? (
                                    <EditMetricForm setState={setState} report={report as Report} reportItem={!!reportItem && reportItem || null} setAddDisabled={setAddDisabled} setEditDisabled={setEditDisabled} setAddEditInfoDisabled={setAddEditInfoDisabled} setRefreshTable={setRefreshTable}/>
                                ) : ('')
                            }
                        </div>

                    </Grid>
                </Box>
            }
            contentType="table"
            ariaLabels={appLayoutLabels}
        />
    );
}
