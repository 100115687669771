import { useState } from 'react';
// import the token here to test that our jest-preset handles it
import * as tokens from '@amzn/awsui-design-tokens';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import Icon from '@amzn/awsui-components-react/polaris/icon';
import Select, { SelectProps } from '@amzn/awsui-components-react/polaris/select';
import Box from '@amzn/awsui-components-react/polaris/box';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import Container from '@amzn/awsui-components-react/polaris/container';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import Header from '@amzn/awsui-components-react/polaris/header';
import Link from '@amzn/awsui-components-react/polaris/link';

const cloudfrontDistributions = [{ value: '1', label: 'Web delivery method' }];

// The content in the main content area of the App layout
export default function HomepageContent() {
  const [selectedOption, setSelectedOption] = useState<SelectProps.Option>(cloudfrontDistributions[0]);
  return (
    <>
      <div style={{ background: tokens.colorBackgroundHomeHeader }}>
        <Box padding={{ vertical: 'xxl', horizontal: 's' }}>
          <Grid
            gridDefinition={[
              { offset: { l: 2, xxs: 1 }, colspan: { l: 8, xxs: 10 } },
              { colspan: { l: 5, s: 6, xxs: 10 }, offset: { l: 2, xxs: 1 } },
              { colspan: { l: 3, s: 4, xxs: 10 }, offset: { s: 0, xxs: 1 } }
            ]}
          >
            <Box padding={{ top: 'xs' }} fontWeight="light">
              <span className="custom-home-text-inverted"></span>
            </Box>
            <div className="custom-home-text-inverted">
              <Box variant="h1" fontWeight="bold" padding="n" fontSize="display-l" color="inherit">
                Fondue Reporting
              </Box>
              <Box fontWeight="light" padding={{ bottom: 's' }} fontSize="display-l" color="inherit">
                Reporting Tool for AWS Identity Business Metrics Data
              </Box>
            </div>
            <Container>
              <SpaceBetween size="xl">
                <Box variant="h2" padding="n">
                  Get started creating reports
                </Box>
                <Button variant="primary"  href={`#reports/create`}>Create Report</Button>
              </SpaceBetween>
            </Container>
          </Grid>
        </Box>
      </div>

      <Box padding={{ top: 'xxl', horizontal: 's', bottom: 'l' }}>
        <Grid
          gridDefinition={[
            { colspan: { xl: 6, l: 5, s: 6, xxs: 10 }, offset: { l: 2, xxs: 1 } },
            { colspan: { xl: 2, l: 3, s: 4, xxs: 10 }, offset: { s: 0, xxs: 1 } }
          ]}
        >
          <SpaceBetween size="l">
            <div>
              <Header variant="h1" headingTagOverride="h2">
                Benefits and features
              </Header>
              <Container>
                <ColumnLayout columns={2} variant="text-grid">
                  <div>
                    <Box variant="h3" padding={{ top: 'n' }}>
                      Easily build weekly reports
                    </Box>
                    <Box variant="p">
                      Fondue provides a point-and-click interface to help you easily build weekly business metrics reports.
                    </Box>
                  </div>
                  <div>
                    <Box variant="h3" padding={{ top: 'n' }}>
                      Automated generation
                    </Box>
                    <Box variant="p">
                      Fondue runs your report every Monday morning to give you the latest data from the previous week.
                    </Box>
                  </div>
                  <div>
                    <Box variant="h3" padding={{ top: 'n' }}>
                      Share with stakeholders
                    </Box>
                    <Box variant="p">
                      Fondue lets you easily share weekly business reports with other stakeholders who can view the reports in the tool.
                    </Box>
                  </div>
                  <div>
                    <Box variant="h3" padding={{ top: 'n' }}>
                      WBR-compatible export
                    </Box>
                    <Box variant="p">
                      Fondue can export your weekly report to a WBR-compatible excel sheet.
                    </Box>
                  </div>
                </ColumnLayout>
              </Container>
            </div>
            <div>
              <Header variant="h1" headingTagOverride="h2">
                Data Sources
              </Header>
              <Container>
                <ColumnLayout columns={1} variant="text-grid">
                  <div>
                    <Box variant="h3" padding={{ top: 'n' }}>
                      Identity Datawarehouse
                    </Box>
                    <Box variant="p">
                      Contains business data for AWS Sign-in, Sign-up, Directory Service,  Organizations, IAM, Single Sign-On, and Service Quotas
                    </Box>
                    {/*
                    TODO: Implement this when data is available
                    <Link href="#" external={true}>
                      Learn more
                    </Link>
                    */}
                  </div>
                </ColumnLayout>
              </Container>
            </div>
          </SpaceBetween>
          <SpaceBetween size="xxl" className="custom-home__sidebar">
            <Container
              header={
                <Header variant="h2">
                  Getting started <Icon name="external" />
                </Header>
              }
            >
              <ul aria-label="Getting started tutorial" className="custom-list-separator">
                <li>
                  <Link
                    href="https://w.amazon.com/bin/view/BusinessMetrics/Fondue/#HGettingStarted"
                    target="_blank"
                  >
                    Getting Started Tutorial
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://w.amazon.com/bin/view/BusinessMetrics/Fondue/#HSQLQueryExamples"
                    target="_blank"
                  >
                    SQL Query Examples
                  </Link>
                </li>
              </ul>
            </Container>

            <Container
              header={
                <Header variant="h2">
                  More resources <Icon name="external" />
                </Header>
              }
            >
              <ul aria-label="Additional resource links" className="custom-list-separator">
                <li>
                  <Link href="https://w.amazon.com/bin/view/BusinessMetrics/Fondue" target="_blank">
                    Documentation
                  </Link>
                </li>
                <li>
                  <Link href="https://w.amazon.com/bin/view/BusinessMetrics/Fondue#HFAQ" target="_blank">
                    FAQ
                  </Link>
                </li>
                <li>
                  <Link href="https://w.amazon.com/bin/view/BusinessMetrics/Fondue/Metrics/" target="_blank">
                    Metrics Tracked through Fondue Reports
                  </Link>
                </li>
                <li>
                  <Link href="https://w.amazon.com/bin/view/BusinessMetrics/IAMDWTablesSchemaAndMetrics/" target="_blank">
                    AWS Identity Data Dictionary
                  </Link>
                </li>
                <li>
                  <Link href="https://w.amazon.com/bin/view/IDAS/OperationalExcellence/AuditAndReporting/BusinessMetrics/ContactUs" target="_blank">Contact Us</Link>
                </li>
              </ul>
            </Container>
          </SpaceBetween>
        </Grid>
      </Box>
    </>
  );
}
