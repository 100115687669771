import { Form, SpaceBetween, Button, FormField, Input, Textarea, Toggle, Checkbox, Select, SelectProps } from "@amzn/awsui-components-react";
import React, { useEffect, useState } from "react";
import { ReportItem } from "../../../fondue-api/generated-src";

const EmptyFilterError = "Cannot be empty";

const PolicyDEROutputTypes = {
    counts: { value: "counts", label: "Count(*)" },
    account_numbers: { value: "account_numbers", label: "Account Numbers", disabled: true },
    owner_id: { value: "owner_id", label: "Account Owner", disabled: true },
    all_columns: { value: "all_columns", label: "All Columns", disabled: true }
};

const PolicyDEREffectTypes = {
    allow: { value: "allow", label: "Allow" },
    deny: { value: "deny", label: "Deny" }
};

export interface DerReportProps {
    clearFields: () => void;
    createReportItem: (reportItemConfig?: {}) => void;
    disabled: boolean;
    itemNameState: [string, React.Dispatch<React.SetStateAction<string>>];
    itemDescriptionState: [string, React.Dispatch<React.SetStateAction<string>>];
    itemQueryState: [string, React.Dispatch<React.SetStateAction<string>>];
    isEditing?: boolean;
    resetFields?: () => void;
    reportItem?: ReportItem | null;

    enablePolicyFilterForm?: boolean; //Enable or disable filter form
}

export default function DerReportItemForm({ itemNameState, itemDescriptionState, itemQueryState, clearFields, createReportItem, disabled, ...props}: DerReportProps) {
    const [itemName, setItemName] = itemNameState;
    const [itemDescription, setItemDescription] = itemDescriptionState;
    const [itemQuery, setItemQuery] = itemQueryState;

    let der_config = props.reportItem?.report_item_config?.["der_config"];
    
    const [useSqlQuery, setUseSqlQuery] = useState(der_config?.["use_filter_form"] == false);

    const [derOutputType, setDerOutputType] = useState<SelectProps.Option>(PolicyDEROutputTypes[der_config?.["output_type"] ?? "counts"]);

    const [useEffectFilter, setUseEffectFilter] = useState(der_config?.["effect"] != undefined);
    const [effect, setEffect] = useState<SelectProps.Option>(PolicyDEREffectTypes[der_config?.["effect"] ?? "allow"]);

    const [useActionFilter, setUseActionFilter] = useState(der_config?.["action"] != undefined);
    const [actionFilter, setActionFilter] = useState<string>(der_config?.["action"] ?? "");
    const [actionError, setActionError] = useState("");

    const [useResourceFilter, setUseResourceFilter] = useState(der_config?.["resource"] != undefined);
    const [resourceFilter, setResourceFilter] = useState<string>(der_config?.["resource"] ?? "");
    const [resourceError, setResourceError] = useState("");

    const [useConditionFilter, setUseConditionFilter] = useState(der_config?.["condition"] != undefined);
    const [conditionFilter, setConditionFilter] = useState<string>(der_config?.["condition"] ?? "");
    const [conditionError, setConditionError] = useState("");

    function clearValidationErrors() {
        setActionError("");
        setResourceError("");
        setConditionError("");
    }

    function validatePolicyForm(): boolean {
        let validationPass = true;
        clearValidationErrors();

        if (useActionFilter && actionFilter == "") {
            setActionError(EmptyFilterError);
            validationPass = false;
        }

        if (useResourceFilter && resourceFilter == "") {
            setResourceError(EmptyFilterError);
            validationPass = false;
        }

        if (useConditionFilter && conditionFilter == "") {
            setConditionError(EmptyFilterError);
            validationPass = false;
        }

        return validationPass;
    }

    function createDerReportItem() {
        if (validatePolicyForm()) {
            createReportItem(buildReportItemConfig());
        }
    }

    function buildReportItemConfig(): any {
        der_config = {
            use_filter_form: !useSqlQuery,
            output_type: derOutputType.value
        }

        if (useEffectFilter) {
            der_config["effect"] = effect.value;
        }

        if (useActionFilter) {
            der_config["action"] = actionFilter;
        }

        if (useResourceFilter) {
            der_config["resource"] = resourceFilter;
        }

        if (useConditionFilter) {
            der_config["condition"] = conditionFilter;
        }

        return {"der_config": der_config};
    } 

    return (
        <Form actions={
            <SpaceBetween direction="horizontal" size="xs">
                <Button id="cancel" variant="normal" onClick={clearFields} disabled={disabled}>
                    Cancel
                </Button>
                {
                    props.isEditing === true
                    && <Button id="reset" variant="normal" onClick={props.resetFields ?? (() => {})} disabled={disabled}>
                        Reset
                    </Button>
                }
                <Button id="submit" variant="primary" onClick={() => createDerReportItem()} disabled={disabled}>
                    Submit
                </Button>
            </SpaceBetween>
        }>
            <FormField
                id="name"
                label="Item Name" description="Name of item">
                <Input
                    value={itemName}
                    onChange={event =>
                        setItemName(event.detail.value)
                    }
                />
            </FormField>
            <FormField
                id="description"
                label={
                    <span>
                        Item Description <i> - optional </i>{" "}
                    </span>
                }
            >
                <Textarea
                    value={itemDescription}
                    rows={3}
                    onChange={({ detail }) =>
                        setItemDescription(detail.value)}
                />
            </FormField>
            <FormField id="policy-scan-filter">
                <Toggle checked={useSqlQuery} onChange={({ detail }) => { setUseSqlQuery(detail.checked) }}>
                    Use SQL Query
                </Toggle>
            </FormField>
            {
                useSqlQuery
                    ? <FormField
                        id="query"
                        label={
                            <span>
                                Policy Scan SQL Query
                            </span>
                        }
                        description="SQL query for AspenTricorder output in Redshift cluster"
                    >
                        <Textarea
                            value={itemQuery}
                            rows={15}
                            onChange={({ detail }) =>
                                setItemQuery(detail.value)}
                            placeholder={"SELECT count(distinct resource_share_id) FROM ram.resource_shares WHERE end_date = CAST('IAM_WBR_DT' as DATE);"}
                        />
                    </FormField>
                    : <React.Fragment>
                        <FormField id="output-type" label={<span>Output Type</span>} stretch>
                            <Select selectedOption={derOutputType}
                                onChange={({ detail }) =>
                                    setDerOutputType(detail.selectedOption)
                                }
                                options={Object.keys(PolicyDEROutputTypes).map((key) => PolicyDEROutputTypes[key])}
                                selectedAriaLabel="Selected" />
                        </FormField>
                        <FormField id="use-effect-filter">
                            <Checkbox checked={useEffectFilter} onChange={({ detail }) => { setUseEffectFilter(detail.checked) }}>
                                Effect Filter
                            </Checkbox>
                        </FormField>
                        <FormField id="effect-filter">
                            <Select disabled={!useEffectFilter}
                                selectedOption={effect}
                                onChange={({ detail }) => { setEffect(detail.selectedOption) }}
                                options={Object.keys(PolicyDEREffectTypes).map((key) => PolicyDEREffectTypes[key])}
                                selectedAriaLabel="Selected" />
                        </FormField>
                        <FormField id="use-action-filter">
                            <Checkbox checked={useActionFilter} onChange={({detail}) => {setUseActionFilter(detail.checked)}}>
                                Action Filter
                            </Checkbox>
                        </FormField>
                        <FormField id="action-filter" constraintText="(Separated by new line)" errorText={actionError}>
                            <Textarea disabled={!useActionFilter} value={actionFilter}
                             onChange={({detail}) => setActionFilter(detail.value)} placeholder={"s3:*\ns3:Get*\n..."}/>
                        </FormField>
                        <FormField id="use-resource-filter">
                            <Checkbox checked={useResourceFilter} onChange={({detail}) => {setUseResourceFilter(detail.checked)}}>
                                Resource Filter
                            </Checkbox>
                        </FormField>
                        <FormField id="resource-filter" constraintText="(Separated by new line)"  errorText={resourceError}>
                            <Textarea disabled={!useResourceFilter} value={resourceFilter}
                             onChange={({detail}) => setResourceFilter(detail.value)} placeholder="arn.aws::*"/>
                        </FormField>
                        <FormField id="use-condition-filter">
                            <Checkbox checked={useConditionFilter} onChange={({detail}) => {setUseConditionFilter(detail.checked)}}>
                                Condition Filter
                            </Checkbox>
                        </FormField>
                        <FormField id="condition-filter" constraintText="(Separated by new line)"  errorText={conditionError}>
                            <Textarea disabled={!useConditionFilter} value={conditionFilter}
                             onChange={({detail}) => setConditionFilter(detail.value)}
                             placeholder={"\"NumericLessThanEquals\": {\"aws:MultiFactorAuthAge\": \"3600\"}"}/>
                        </FormField>
                    </React.Fragment>
            }
        </Form>
    );
}